import gql from "graphql-tag";

export const CREATE_CLASS_OF_BUSINESS = gql`
  mutation CreateClassOfBusiness(
    $businessName: String!
    $business_details: String!
    $businessGroup: String
  ) {
    createClassOfBusiness(
      business_name: $businessName
      business_category: $businessGroup
      business_details: $business_details
    ) {
      class_of_business_id
      business_name
      business_category
      business_details
      created_at
    }
  }
`;

export const DELETE_CLASS_OF_BUSINESS = gql`
  mutation DeleteClassOfBusiness($id: ID!) {
    removeSingleClassOfBusiness(class_of_business_id: $id)
  }
`;





export const UPDATE_CLASS_OF_BUSINESS = gql`
  mutation UpdateClassOFBusiness(
    $id: ID!
    $details: String!
    $businessName: String!
    $businessGroup: String
  ) {
    updateSingleClassOfBusiness(
      class_of_business_id: $id
      business_details: $details
      business_name: $businessName
      business_category: $businessGroup
    ) {
      class_of_business_id
      business_name
      business_details
      business_category
    }
  }
`;

export const CREATE_REINSURER = gql`
  mutation CreateReinsurer(
    $re_company_name: String!
    $re_company_email: String!
    $re_company_website: String!
    $street: String!
    $suburb: String!
    $region: String!
    $country: String!
  ) {
    createReinsurer(
      reinsurer: {
        region: $region
        re_company_name: $re_company_name
        re_company_email: $re_company_email
        re_company_website: $re_company_website
        street: $street
        suburb: $suburb
        country: $country
      }
    ) {
      reinsurer_id
    }
  }
`;

export const SEND_BROKER_CLOSING_SLIP = gql`
  mutation SendBrokerClosingSlip(
    $treaty_id: ID
    $treaty_account_id: ID
    $re_broker_treaties_participation_id: ID
    $emaildata: EmailComponent
  ) {
    sendTreatyBrokerNotes(
      treaty_id: $treaty_id
      treaty_account_id: $treaty_account_id
      re_broker_treaties_participation_id: $re_broker_treaties_participation_id
      emaildata: $emaildata
    )
  }
`;

export const CREATE_ASSOCIATE = gql`
  mutation CreateAssociate(
    $reinsurer_id: ID!
    $first_name: String!
    $last_name: String!
    $phone_pri: String!
    $phone_sec: String
    $position: String!
    $email: String!
  ) {
    createReinsurerRep(
      reinsurer_rep: {
        reinsurersreinsurer_id: $reinsurer_id
        rep_first_name: $first_name
        rep_last_name: $last_name
        rep_email: $email
        position: $position
        rep_primary_phonenumber: $phone_pri
        rep_secondary_phonenumber: $phone_sec
      }
    ) {
      reinsurersreinsurer_id
    }
  }
`;

export const REMOVE_ASSOCIATE = gql`
  mutation RemoveAssociate($id: ID!) {
    removeReinsuereRep(reinsurer_representative_id: $id) {
      reinsurer_representative_id
    }
  }
`;

export const REMOVE_REINSURER = gql`
  mutation removeReinsurer($id: ID!) {
    removeReinsurer(reinsurer_id: $id) {
      reinsurer_id
    }
  }
`;

export const CREATE_INPUT_OFFER = gql`
  mutation CreateOffer(
    $class_of_business_id: ID!
    $insurer_id: ID!
    $employee_id: ID!
    $rate: Float!
    $commission: Float!
    $brokerage: Float!
    $facultative_offer: Float!
    $sum_insured: Float!
    $premium: Float!
    $policy_number: String
    $insured_by: String!
    $period_of_insurance_from: Date!
    $period_of_insurance_to: Date!
    $currency: String!
    $offer_comment: String
    $offer_details: String!
  ) {
    createInputOffer(
      offer_input: {
        class_of_business_id: $class_of_business_id
        insurer_id: $insurer_id
        employee_id: $employee_id
        rate: $rate
        commission: $commission
        brokerage: $brokerage
        facultative_offer: $facultative_offer
        sum_insured: $sum_insured
        premium: $premium
        policy_number: $policy_number
        insured_by: $insured_by
        period_of_insurance_from: $period_of_insurance_from
        period_of_insurance_to: $period_of_insurance_to
        currency: $currency
        offer_comment: $offer_comment
        offer_details: $offer_details
      }
    ) {
      rate
    }
  }
`;


export const CREATE_FLEET_OFFER = gql`
  mutation CreateFleetOffer($data: [Offer_data]) {
    createFleetOffer(offer_input: $data)
  }
`;

export const CREATE_DISTRIBUTION_LIST = gql`
  mutation CreateDistributionList(
    $offer_id: ID!
    $reinsurer_reps: [Reinsurer_representative_data!]!
  ) {
    createDistributionList(
      distribution_list: {
        offer_id: $offer_id
        reinsurer_reps: $reinsurer_reps
      }
    )
  }
`;

export const REMOVE_REINSURER_FROM_PARTICIPATION = gql`
  mutation RemoveReinsurerfromList(
    $offer_id: ID!
    $reinsurer_id: ID!
    $offer_participating_id: ID!
  ) {
    removeParticipantByReinsurer(
      offer_participating_id: $offer_participating_id
      offer_id: $offer_id
      reinsurer_id: $reinsurer_id
    )
  }
`;

export const REMOVE_ASSOCIATE_FROM_PARTICIPATION = gql`
  mutation RemoveAssociateFromParticipation($offer_to_associate_id: ID!) {
    removeParticipantByParticipant(
      offer_to_associate_id: $offer_to_associate_id
    )
  }
`;

export const ADD_PERCENTAGE = gql`
  mutation AddPercentage(
    $offer_participant_id: ID!
    $offer_id: ID!
    $percentage: Float!
  ) {
    addPrecentageToParticipant(
      offer_participant_id: $offer_participant_id
      offer_id: $offer_id
      participating_percentage: $percentage
    ) {
      offer_amount
    }
  }
`;

export const CLOSE_OFFER = gql`
  mutation CloseOffer($offer_id: ID!, $data: [Reinsurer_extra_charge!]!) {
    createClosingForOffer(
      extra_charges: { offer_id: $offer_id, reinsurer_data: $data }
    ) {
      nic_levy_amount
    }
  }
`;

export const UPDATE_OFFER = gql`
  mutation UpdateOffer(
    $offer_id: ID!
    $offer_input: Offer_data!
    $offer_detail_id: ID!
  ) {
    updateInputOffer(
      offer_input: $offer_input
      offer_id: $offer_id
      offer_detail_id: $offer_detail_id
    )
  }
`;

export const CREATE_INSURER = gql`
  mutation CreateInsurer(
    $insurer_company_name: String!
    $insurer_company_email: String!
    $insurer_company_website: String!
    $street: String!
    $suburb: String!
    $region: String!
    $country: String!
  ) {
    createInsurer(
      insurer: {
        insurer_company_name: $insurer_company_name
        insurer_company_email: $insurer_company_email
        insurer_company_website: $insurer_company_website
        street: $street
        suburb: $suburb
        region: $region
        country: $country
      }
    ) {
      insurer_id
    }
  }
`;

export const CREATE_INSURER_REP = gql`
  mutation CreateInsurerRep(
    $insurer_id: ID!
    $first_name: String!
    $last_name: String!
    $phone_pri: String!
    $phone_sec: String
    $email: String!
    $position: String!
  ) {
    createInsurerRep(
      insurer_rep: {
        insurersinsurer_id: $insurer_id
        rep_first_name: $first_name
        rep_last_name: $last_name
        rep_primary_phonenumber: $phone_pri
        rep_secondary_phonenumber: $phone_sec
        rep_email: $email
        position: $position
      }
    ) {
      insurer_associate_id
    }
  }
`;

export const REMOVE_INSURER = gql`
  mutation RemoveInsurer($id: ID!) {
    removeInsurer(insurer_id: $id)
  }
`;

export const UPDATE_INSURER = gql`
  mutation UpdateInsurer($insurer_id: ID!, $insurer: Insurer_data!) {
    updateInsurer(insurer_id: $insurer_id, insurer: $insurer)
  }
`;

export const UPDATE_INSURER_MANAGER = gql`
  mutation UpdateManager($id: ID!, $manager: Insurer_associate_data!) {
    updateInsurerRep(insurer_rep_id: $id, insurer_rep: $manager)
  }
`;

export const REMOVE_INSURER_MANAGER = gql`
  mutation RemoveManager($id: ID!) {
    removeInsurerRep(insurer_rep_id: $id)
  }
`;

export const UPDATE_REINSURER = gql`
  mutation UpdateReinsurer($reinsurer_id: ID!, $reinsurer: Reinsurer_data!) {
    updateReinsurer(reinsurer_id: $reinsurer_id, reinsurer: $reinsurer)
  }
`;

export const MAKE_PAYMENT_INSURER = gql`
  mutation MakePaymentOnOffer($data: Offer_payment_data!) {
    makePaymentOnOffer(payment_info: $data)
  }
`;
export const MAKE_PAYMENT_RE_INSURER_CLAIM = gql`
 mutation CreateOfferClaimPayment(
  $claimPayments: OfferClaimParticipantPaymentInput!
) {
  createOfferClaimPayment(claim_payments: $claimPayments)
}
`;

export const UPDATE_PAYMENT_INSURER = gql`
  mutation CreatePaymentInsurer($data: Offer_payment_update_data!) {
    updatePaymentOnofferFromInsurer(payment_info: $data)
  }
`;

export const UPDATE_PAYMENT_REINSURER = gql`
  mutation UpdateReinsurerPayment(
    $offer_participant_payment_id: ID!
    $paid_details: String
    $Offer_payment_comment: String
  ) {
    updateReinsurerPaymentDetails(
      offer_participant_payment_id: $offer_participant_payment_id
      paid_details: $paid_details
      offer_participant_payment_comment: $Offer_payment_comment
    )
  }
`;

export const REMOVE_PAYMENT = gql`
  mutation RemovePayment($id: ID!) {
    deleteOfferPaymentFromInsurer(offer_payment_id: $id)
  }
`;

export const DISTRIBUTE_PAYMENT = gql`
  mutation DistributePayments($data: Offer_participant_payment_update!) {
    updateOfferParticipantPayment(participant_payment_details: $data)
  }
`;

export const UPDATE_REINSURER_ASSOCIATE = gql`
  mutation UpdateReinsurerRep(
    $rep: Reinsurer_rep_data!
    $reinsurer_representative_id: ID!
  ) {
    updateReinsurerRep(
      reinsurer_rep: $rep
      reinsurer_representative_id: $reinsurer_representative_id
    )
  }
`;

export const MAKE_CLAIM_ON_OFFER = gql`
  mutation MakeClaimOnOFfer($data: Claim_data!) {
    makeClaimOnOffer(claim_data: $data)
  }
`;

export const DELETE_OFFER = gql`
  mutation DeleteOffer($id: ID!) {
    deleteOfferFromSystem(offer_id: $id)
  }
`;

export const SEND_DEBIT_AND_CREDIT = gql`
  mutation SendDebitAndCoverNotes($data: Email_data!) {
    sendDebitAndCoverNotes(email_data: $data)
  }
`;

export const SEND_OFFER_AS_BROADCAST = gql`
  mutation SendOfferAsBroadCast(
    $data: Email_data!
    $should_send: Int
    $include_attachment: Boolean
  ) {
    sendOfferAsBroadCast(
      email_data: $data
      should_send: $should_send
      include_attachment: $include_attachment
    )
  }
`;
export const SEND_CLOSING_SLIP = gql`
  mutation SendClosingslip($data: Email_data!) {
    sendClosingslip(email_data: $data)
  }
`;

export const CLAIM_REQUEST = gql`
  mutation SendClaimNotification($data: Email_data!) {
    sendClaimNotification(email_data: $data)
  }
`;

export const REMOVE_CLAIM_AMOUNT = gql`
  mutation DeleteClaimAmount($id: ID!, $offer_id: ID!) {
    deleteClaimAmount(offer_claim_id: $id, offer_id: $offer_id)
  }
`;

export const UPDATE_CLAIM_AMOUNT = gql`
  mutation UpdateClaimAmount($claim_id: ID!, $data: Claim_data!) {
    updateClaimAmount(offer_claim_id: $claim_id, claim_data: $data)
  }
`;

export const SEND_CLAIM_DEBIT_NOTE = gql`
  mutation SendClaimDebitNote(
    $offer_claim_participant_id: ID!
    $offer_id: ID!
    $reinsurer_id: ID!
    $subject: String
    $copied_emails: [String]
    $message_content: String
  ) {
    sendClaimDebitNote(
      offer_claim_participant_id: $offer_claim_participant_id
      offer_id: $offer_id
      reinsurer_id: $reinsurer_id
      subject: $subject
      copied_emails: $copied_emails
      message_content: $message_content
    )
  }
`;

export const UPDATE_EXTRA_CHARGE = gql`
  mutation UpdateReinsurerExtraCharges(
    $id: ID!
    $participatant_id: ID!
    $data: Offer_edit_extra_charge_data!
  ) {
    updateReinsurerExtraCharges(
      extra_charge_id: $id
      participant_id: $participatant_id
      extra_charge: $data
    )
  }
`;
