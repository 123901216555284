export const columns = [
  {
    label: "Policy #",
    field: "policy_number",
    sort: "asc",
  },
  {
    label: "Insurance Company",
    field: "insurance_company",
    sort: "asc",
  },
  {
    label: "Insured",
    field: "insured",
    sort: "asc",
  },
  {
    label: "Class Of Business",
    field: "cob",
    sort: "asc",
  },
  {
    label: "Sum Insured",
    field: "sum_insured",
    sort: "asc",
  },
  {
    label: "Rate",
    field: "rate",
    sort: "asc",
  },
  {
    label: "Status",
    field: "offer_status",
    sort: "asc",
  },
  {
    label: "Offer Date",
    field: "offer_date",
    sort: "asc",
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
  },
];

export const reinsurersColumns = [
  {
    label: "Company Name",
    field: "company_name",
  },
  {
    label: "Company Email",
    field: "company_email",
  },
  // {
  //   label: "No. Reps",
  //   field: "no_of_reps",
  // },
  {
    label: "Participant %",
    field: "participant_percentage",
  },
  {
    label: "Action",
    field: "actions",
  },
];

export const associatesColumns = [
  {
    label: "Company Name",
    field: "company_name",
  },
  {
    label: "Company Email",
    field: "company_email",
  },
  {
    label: "Rep. Name",
    field: "rep_name",
  },
  {
    label: "Rep. Phone Number",
    field: "rep_phone",
  },
  {
    label: "	Rep. Email",
    field: "rep_email",
  },
  {
    label: "Email Status",
    field: "email_status",
  },
  {
    label: "Action",
    field: "actions",
  },
];

export const responseFound = [
  {
    label: "Reinsurer",
    field: "reinsurer",
  },
  {
    label: "Representative name",
    field: "rep_name",
  },
  {
    label: "Status",
    field: "status",
  },
];

export const previewTable = [
  {
    label: "Sum Insured",
    field: "sum_insured",
  },
  {
    label: "Premium",
    field: "premium",
  },
  {
    label: "Rate",
    field: "rate",
  },
  {
    label: "Fac. Offer",
    field: "facultative_offer",
  },
  {
    label: "Commission",
    field: "commission",
  },
  {
    label: "Brokerage",
    field: "brokerage",
  },
  {
    label: "Period",
    field: "period",
  },
  {
    label: "Business Details",
    field: "offer_details",
  },
  {
    label: "Action",
    field: "actions",
  },
];
