import gql from "graphql-tag";

export const EMPLOYEES = gql`
  query Employees {
    employees {
      emp_abbrv
      employee_id
      employee_email
      employee_last_name
      employee_first_name
      employee_phonenumber
      user {
        position
      }
    }
  }
`;

export const ASSIGNED_CEDANTS = gql`
  query AssignedToMe($staffId: ID) {
    assignedToMe(staff_id: $staffId) {
      insurer_id
      insurer_company_name
      insurer_company_email
      insurer_company_website
      insurer_abbrv
      insurer_address {
        insurer_address_id
        street
        suburb
        region
        country
      }
    }
  }
`;
