import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { AssignedToMe_assignedToMe } from "../../../graphql/queries/__generated__/AssignedToMe";
import { useAssignedCedants } from "../../../hooks/useAssignedCedants";
import { SELECT_INSURER } from "../../../redux/types/InsurerTypes";

type Props = {};

// const columns: any[] = [
//   {
//     label: "Insurer Abbreviation",
//     field: "insurer_abbrv",
//     sort: "asc",
//   },
//   {
//     label: "Insurer",
//     field: "insurer_company_name",
//     sort: "asc",
//   },
//   {
//     label: "Actions",
//     field: "actions",
//     sort: "asc",
//   },
// ];

type AssignedButtonsProps = {
  cedant: AssignedToMe_assignedToMe;
};

// const AssignedButtons = (props: AssignedButtonsProps) => {
//   return (
//     <>
//       <Link className="btn btn-primary btn-sm btn-square" to={""}>
//         Go to Insurer
//       </Link>
//     </>
//   );
// };

const AsignedCedant = ({ cedant }: AssignedButtonsProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const navigateToDetails = () => {
    dispatch({
      type: SELECT_INSURER,
      payload: cedant.insurer_id,
    });
    history.push({
      pathname: "/admin/insurers-details",
      state: { insurer_id: cedant.insurer_id },
    });
  };
  return (
    <div onClick={navigateToDetails} className="col-xl-3 col-sm-6 p-1">
      <div className="border link-hover">
        <div className="card-body row">
          <div className="avatar-sm col-md-3">
            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-16">
              {cedant.insurer_abbrv}
            </span>
          </div>
          <div className="col-md-9 d-flex  align-items-start">
            <h5 className="font-size-15">
              <span className="text-dark">{cedant.insurer_company_name}</span>
            </h5>
            <div
              className="flex-fill link-hover d-flex align-items-center justify-content-center"
              data-toggle="tooltip"
              data-placement="top"
              title="View"
            >
              <i className="bx bx-arrow-right-alt"></i>
              {/* <span className="font-size-14 ml-2">Go to Insurer</span> */}
            </div>
          </div>
        </div>
        {/* <div className="card-footer bg-transparent border-top">
          <div className="contact-links d-flex font-size-20"></div>
        </div> */}
      </div>
    </div>
  );
};

const AssignedInsurers = (props: Props) => {
  const { user } = useAuth() as any;
  const { cedants } = useAssignedCedants();
  if (["Managing Director", "System Administrator"].includes(user?.position))
    return null;
  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">Cedants assgined to you</h4>
              <div className="row m-2 d-flex align-items-stretch">
                {cedants?.map((el, idx) => (
                  <AsignedCedant
                    key={idx}
                    cedant={el as AssignedToMe_assignedToMe}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AssignedInsurers;
