import { lazy } from "react";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const CreateSlip = lazy(() => import("./pages/CreateSlip"));
const ViewOffer = lazy(() => import("./pages/CreateSlip/ViewOfferWrapper"));
const CreateClosing = lazy(() => import("./pages/CreateClosing"));
const UnapprovedItems = lazy(() => import("./pages/UnapprovedClosings"));
const ReInsurers = lazy(() => import("./pages/ReInsurers"));
const SetupBusiness = lazy(() => import("./pages/SetUpBusiness"));
const Insurers = lazy(() => import("./pages/Insurers"));
const InsurersDetails = lazy(() =>
  import("./pages/Insurers/InsurerDetailWrapper")
);
const Reports = lazy(() => import("./pages/Reports"));
const Employees = lazy(() => import("./pages/Employees"));
const Claims = lazy(() => import("./pages/Claims"));
const ReinsurersDetails = lazy(() =>
  import("./pages/ReInsurers/ReinsurerDetail")
);
const Notifications = lazy(() => import("./pages/Notifications"));

const Profile = lazy(() => import("./pages/profile"));
const TreatyPrograms = lazy(() => import("./pages/TreatyPrograms"));
const TreatyProgramsOverview = lazy(() =>
  import("./pages/Insurers/ViewTreatyWrapper")
);
const TreatyClaims = lazy(() => import("./pages/TreatyClaims"));

const Brokers = lazy(() => import("./pages/Brokers"));
const BrokerDetails = lazy(() => import("./pages/BrokerDetails"));
const GenerateBrokerPaymentSchedule = lazy(() =>
  import("./pages/BrokerGeneratePaymentSchedule")
);
const CreateTreaty = lazy(() => import("./pages/CreateTreaty"));
const ManageTreatyDetails = lazy(() => import("./pages/ManageTreatyDetails"));
// const DeletedOffers = lazy(() => import("./pages/DeletedOffers"));
const GenerateSchedule = lazy(() => import("./pages/GenerateSchedule"));

const Tests = lazy(() => import("./pages/Test"));
const ViewClaims = lazy(() => import("./pages/ViewClaims"));

export default [
  {
    path: "/",
    layout: "/admin",
    component: Dashboard,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/notifications",
    layout: "/admin",
    component: Notifications,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/profile",
    layout: "/admin",
    component: Profile,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/create-slip",
    layout: "/admin",
    component: CreateSlip,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/re-insurers",
    layout: "/admin",
    component: ReInsurers,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/insurers",
    layout: "/admin",
    component: Insurers,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/brokers",
    layout: "/admin",
    name: "Reinsurance Brokers",
    component: Brokers,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/brokers/details/:id",
    layout: "/admin",
    name: "Reinsurance Brokers",
    component: BrokerDetails, // BrokerDetails
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/claims/details/:id",
    layout: "/admin",
    name: "View Claims",
    component: ViewClaims, // ViewClaims
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/insurers-details/recent/:id/generate-payment-schedule",
    layout: "/admin",
    name: "Reinsurance Brokers",
    component: GenerateSchedule,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/insurers-details/all/:id/generate-payment-schedule",
    layout: "/admin",
    name: "Reinsurance Brokers",
    component: GenerateSchedule,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/treaty-programs/overview/manage/:payload",
    layout: "/admin",
    name: "Treaty Programs",
    component: ManageTreatyDetails,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/insurers-details/recent/:id/create-treaty",
    layout: "/admin",
    name: "Insurers",
    component: CreateTreaty, // Create treaty page
    roles: [
      "CEO",
      "General Manager",
      "Senior Broking Officer",
      "Finance Executive",
      "System Administrator",
    ],
  },
  // {
  //   path: "/insurers-details/recent/:id/generate-payment-schedule",
  //   layout: "/admin",
  //   name: "Reinsurance Brokers",
  //   component: GenerateSchedule, // GenerateBrokerPaymentSchedule
  //   roles: [
  //     "CEO",
  //     "General Manager",
  //     "Senior Broking Officer",
  //     "Finance Executive",
  //     "System Administrator",
  //   ],
  // },
  {
    path: "/insurers-details/all/:id/generate-payment-schedule",
    layout: "/admin",
    name: "Reinsurance Brokers",
    component: GenerateBrokerPaymentSchedule, // GenerateBrokerPaymentSchedule
    roles: [
      "CEO",
      "General Manager",
      "Senior Broking Officer",
      "Finance Executive",
      "System Administrator",
    ],
  },
  {
    path: "/employees",
    layout: "/admin",
    component: Employees,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "System Administrator",
    ],
  },
  {
    path: "/reports",
    layout: "/admin",
    component: Reports,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/claims",
    layout: "/admin",
    component: Claims,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/insurers-details",
    layout: "/admin",
    component: InsurersDetails,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/re-insurers-detail",
    layout: "/admin",
    component: ReinsurersDetails,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/re-insurers-detail/:type",
    layout: "/admin",
    component: ReinsurersDetails,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/setup-business",
    layout: "/admin",
    component: SetupBusiness,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/treaty-programs",
    layout: "/admin",
    component: TreatyPrograms,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/treaty-claims",
    layout: "/admin",
    component: TreatyClaims,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/forms",
    layout: "/admin",
    component: Tests,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/treaty-programs/overview",
    layout: "/admin",
    component: TreatyProgramsOverview,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/create-closing",
    layout: "/admin",
    component: CreateClosing,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  // {
  //   path: "/endorsements",
  //   layout: "/admin",
  //   component: UnapprovedItems,
  //   roles: [
  //     "Managing Director",
  //     "Senior Broking Officer",
  //     "Broking Officer",
  //     "Frontline Executive",
  //     "Finance Officer",
  //     "System Administrator",
  //   ],
  // },
  {
    path: "/view-offer",
    layout: "/admin",
    component: ViewOffer,
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
  {
    path: "/",
    layout: "/auth",
    component: Login,
  },
  {
    path: "/create-password",
    layout: "/auth",
    component: ResetPassword,
  },
];
