/* eslint-disable no-throw-literal */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useMemo } from "react";
import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { Modal, Input, ErrorMessage, Editor } from ".";
import { Select } from "./Input";
import { TREATY_CLAIMS, UPDATE_TREATY_CLAIM } from "../graphql/queries/treaty";
import _ from "lodash";
const EditTreatyClaimForm = ({ data, setShow, treaty }) => {
  const { register, handleSubmit, errors, setValue, clearError } = useForm();
  const [content, setContent] = React.useState("");
  const [limit, setLimit] = React.useState("");

  const [update] = useMutation(UPDATE_TREATY_CLAIM, {
    refetchQueries: [
      {
        query: TREATY_CLAIMS,
      },
    ],
  });

  const layersOptions = useMemo(() => {
    if (treaty && treaty.layer_limit) {
      const _ = JSON.parse(treaty?.layer_limit || "[]");
      return _.map((_, key) => ({
        label: `Layer ${key + 1}`,
        value: key + 1,
      }));
    }
    return [];
  }, [treaty]);

  useEffect(() => {
    if (data) {
      setValue("policy_number", data.policy_number);
      setValue("insured_name", data.insured_name);
      setValue("claim_number", data.claim_number);
      setValue("claim_paid", data.claim_paid);
      setValue("date_of_loss", data.date_of_loss);
      setValue("layer_number", data.layer_number);
      setValue("treaty_claim_id", data.treaty_claim_id);
      setValue("treaty_comment", data.treaty_comment);
      setValue("expected_deductible", data.expected_deductible);
      const details = JSON.parse(data?.treaty_claim_details ?? "{}");
      setValue("third_party_claim_amount", details?.third_party_claim_amount);
      setValue("survey_fees", details?.survey_fees);
      setValue("net_liability_amount", details?.net_liability_amount);
      setValue("company_name", details?.company_name);
      setValue("salvage", details?.salvage);
      setValue("legal_fees", details?.legal_fees);
    }
  }, [data]);

  const onSubmitForm = (values) => {
    const variables = {
      id: values.treaty_claim_id,
      claims: {
        treaty_claim_details: JSON.stringify({
          ..._.omit(values, [
            "policy_number",
            "claim_number",
            "insured_name",
            "date_of_loss",
            "claim_paid",
            "layer_number",
            "treaty_comment",
            "expected_deductible",
          ]),
        }),
        ..._.omit(values, [
          "third_party_claim_amount",
          "survey_fees",
          "net_liability_amount",
          "company_name",
          "salvage",
          "legal_fees",
          "treaty_claim_id",
        ]),
      },
    };
    swal({
      icon: "warning",
      title: "Update claim ?",
      text: "This action would update the selected claim",
      buttons: ["No", { text: "Yes", closeModal: false }],
    }).then((input) => {
      if (!input) throw null;
      update({
        variables,
      })
        .then((res) => {
          swal("Success", "Claim updated successfully", "success");
          setShow(false);
        })
        .catch((err) => {
          swal("Error", "Claim could not update successfully", "error");
        });
    });
  };

  const onLayerChange = (evt) => {
    const { value } = evt.target;
    const __ = JSON.parse(treaty?.limit);
    const _limit = __[parseInt(value) - 1];
    setLimit(_limit);
  };

  const onCommentChange = (value) => {
    setValue("treaty_comment", value);
    setContent(value);
    if (value) {
      clearError("treaty_comment");
    }
  };

  return !data ? null : (
    <Fragment>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmitForm)} className="row p-2">
          <div className="col-md-6">
            <Input
              label="Policy Number"
              placeholder="Policy Number"
              name="policy_number"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="policy_number" />
          </div>

          <div className="col-md-6">
            <Input
              placeholder="Claim Number"
              label="Claim Number"
              name="claim_number"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="claim_number" />
          </div>

          <div className="col-md-12">
            <Input
              label="Insured"
              placeholder="Insured"
              name="insured_name"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="insured_name" />
          </div>

          <div className="col-md-6">
            <Input
              type="date"
              label="Date of Loss"
              placeholder="Date of Loss"
              name="date_of_loss"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="date_of_loss" />
          </div>

          {/* <div className="col-md-6">
                        <Input type="date" label="Claim Date" placeholder="Claim Date" name="claim_date" ref={register({ required: "Required" })} />
                        <ErrorMessage errors={errors} name="claim_date" />
                    </div> */}

          <div className="col-md-6">
            <Input
              type="number"
              step="any"
              label="Claim Paid"
              placeholder="Claim Paid"
              name="claim_paid"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="claim_paid" />
          </div>

          <div className="col-md-6">
            <Select
              options={layersOptions}
              onChange={onLayerChange}
              label="Layer Number"
              name="layer_number"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="layer_number" />
          </div>
          <div className="col-md-6">
            <Input
              type="number"
              step="any"
              label="Expected Deductible"
              placeholder="Expected Deductible"
              name="expected_deductible"
              ref={register({ required: "Required" })}
            />
            <ErrorMessage errors={errors} name="expected_deductible" />
          </div>
          {limit && (
            <div className="col-md-6">
              <Input readOnly label="Deductible" value={limit?.deductible} />
            </div>
          )}
          {limit && (
            <div className="col-md-6">
              <Input readOnly label="limit" value={limit?.limit} />
            </div>
          )}

          <fieldset className="row ml-2 border mr-2 mb-3">
            <legend className="w-auto font-size-16">Claim details</legend>
            <div className="col-md-6">
              <Input
                type="number"
                step="any"
                label="Plus(+) Third party claim amount"
                placeholder="Third party claim amount"
                name="third_party_claim_amount"
                ref={register({ required: false })}
              />
              <ErrorMessage errors={errors} name="third_party_claim_amount" />
            </div>
            <div className="col-md-6">
              <Input
                type="number"
                step="any"
                label="Plus(+) Survey fees"
                placeholder="survey fees"
                name="survey_fees"
                ref={register({ required: false })}
              />
              <ErrorMessage errors={errors} name="survey_fees" />
            </div>{" "}
            <div className="col-md-6">
              <Input
                type="number"
                step="any"
                label="Plus(+) Legal fees"
                placeholder="legal fees"
                name="legal_fees"
                ref={register({ required: false })}
              />
              <ErrorMessage errors={errors} name="legal_fees" />
            </div>{" "}
            <div className="col-md-6">
              <Input
                type="number"
                step="any"
                label="Minus(-) Salvage"
                placeholder="Salvage"
                name="salvage"
                ref={register({ required: false })}
              />
              <ErrorMessage errors={errors} name="salvage" />
            </div>{" "}
            <div className="col-md-6">
              <Input
                type="text"
                step="any"
                label="Company name"
                placeholder="Company name"
                name="company_name"
                ref={register({ required: false })}
              />
              <ErrorMessage errors={errors} name="company_name" />
            </div>{" "}
            <div className="col-md-6">
              <Input
                type="number"
                step="any"
                label="Plus(+) Net liability amount"
                placeholder="Net liability amount"
                name="net_liability_amount"
                ref={register({ required: false })}
              />
              <ErrorMessage errors={errors} name="net_liability_amount" />
            </div>
          </fieldset>

          <div className="col-md-12">
            <label htmlFor="">Claim comment</label>
            <Editor
              value={content || data?.treaty_comment}
              onChange={onCommentChange}
            />
            <input
              type="hidden"
              ref={register({ required: false })}
              name="treaty_comment"
            />
            <div className="mt-2">
              <ErrorMessage errors={errors} name="treaty_comment" />
            </div>
          </div>
          <div className="p-2">
            <button className="btn btn-sm btn-square btn-primary">
              Update
            </button>
            <Input
              type="hidden"
              placeholder="Policy Number"
              name="treaty_claim_id"
              ref={register({ required: "Required" })}
            />
          </div>
        </form>
      </Modal.Body>
    </Fragment>
  );
};

export default EditTreatyClaimForm;
