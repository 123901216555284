/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import { clients, dashboard, offers, others } from "../layout/adminRoutes";

const routes = dashboard.concat(offers).concat(clients).concat(others);

const getPageName = (pathName) => {
  const route = routes.find((el) => el.link === pathName);
  return route?.name;
};

const Content = ({ children, goBack = () => {} }) => {
  const { pathname } = useLocation();
  return (
    <div className="page-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="mb-0 font-size-18">{getPageName(pathname)}</h4>

              <div className="page-title-right">
                <ol
                  onClick={() => goBack && goBack()}
                  className="breadcrumb m-0"
                >
                  <li className="breadcrumb-item">
                    <a>Dashboards</a>
                  </li>
                  <li className="breadcrumb-item active">
                    {getPageName(pathname)}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Content;
