import React, { useState } from "react";
import { dashboard, offers, clients, others, treaty } from "./adminRoutes";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useSelector } from "react-redux";
import { Fragment } from "react";
import { version } from "../../package.json";
import { Modal } from "../components";
import VersionBox from "../components/VersionBox";

const SideBar = () => {
  const { user } = useAuth();
  const { granted } = useSelector((state) => state.app);
  const [showVersion, setShowVersion] = useState(false);
  const { pathname } = useLocation();
  const showSideBarLinks = (routes) => {
    return routes.map((el, key) =>
      el.roles.includes(user?.position) ? (
        <li key={key}>
          <Link
            to={el.link}
            className={`waves-effect ${pathname === el.link ? "active" : ""}`}
          >
            <i className={el.icon}></i>
            <span>{el.name}</span>
          </Link>
        </li>
      ) : null
    );
  };

  return (
    <Fragment>
      <div className="vertical-menu">
        <div data-simplebar className="h-100">
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              {showSideBarLinks(dashboard)}
              <li className="menu-title">Offers</li>
              {showSideBarLinks(offers)}
              <li className="menu-title">Clients</li>
              {showSideBarLinks(clients)}
              <li className="menu-title">others</li>
              {showSideBarLinks(others)}
              {granted && (
                <Fragment>
                  <li className="menu-title">treaty</li>
                  {showSideBarLinks(treaty)}
                </Fragment>
              )}
            </ul>
            <div
              onClick={() => setShowVersion(true)}
              style={{
                // position: "absolute",
                bottom: 0,
                padding: 20,
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Version: {version}
            </div>
          </div>
        </div>
      </div>
      <Modal
        // size="lg"
        centered
        onHide={() => setShowVersion(false)}
        show={showVersion}
      >
        <VersionBox />
      </Modal>
    </Fragment>
  );
};

export default SideBar;
