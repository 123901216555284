import React from "react";
import ReactDom from "react-dom";
import Slide from "react-reveal/Slide";
import { useWindowDimensions } from "../index";
import { AiOutlineClose } from "react-icons/ai";

const BottomDrawer = ({
  children,
  containerStyles = {},
  height,
  isVisible,
  toggle,
  zIndex = 2000,
}) => {
  const { width: R_width } = useWindowDimensions();

  return ReactDom.createPortal(
    <Slide bottom when={isVisible}>
      <div
        onClick={toggle}
        style={{
          display: isVisible ? "block" : "none",
          zIndex,
          position: "fixed",
          right: 0,
          left: 0,
          bottom: 0,
          top: 0,
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        {" "}
      </div>

      <div
        style={{
          zIndex,
          position: "fixed",
          display: isVisible ? "block" : "none",
          right: 0,
          overflowX: !isVisible ? "hidden" : "auto",
          // top: 0,
          bottom: 0,
          height: R_width > 768 ? height : "100%",
          width: "100vw",
          backgroundColor: "#262B3C",
          ...containerStyles,
        }}
      >
        <div className="bg-soft-primary d-flex justify-content-end">
          <div onClick={toggle} className="bg-danger text-white p-1">
            <AiOutlineClose size={25} />
          </div>
        </div>
        {/* {R_width} */}
        {children}
      </div>
    </Slide>,
    document.getElementById("bottom-drawer")
  );
};

export default BottomDrawer;
