import gql from "graphql-tag";

export const INSURER_FIELDS = gql`
  fragment InsurerFields on Insurer {
    insurer_id
    insurer_company_name
    insurer_company_email
    insurer_abbrv
    insurer_company_website
    insurer_address {
      suburb
      country
      street
      region
    }
    remainders {
      remainder_id
      offersoffer_id
      insurersinsurer_id
      postponed_till
    }
    insurer_associates {
      insurer_associate_id
      assoc_last_name
      assoc_email
      position
      assoc_first_name
      assoc_primary_phonenumber
      assoc_secondary_phonenumber
    }
    insurer_overview {
      total_paid
      total_unpaid
      total_closed
      total_pending
      total_fac_premium
      total_brokerage_amt
      brokerage_chart
      treaties_overview {
        total_treaties
        total_proportional
        total_nonproportional
        total_unpaid_treaties
        total_paid_treaties
        total_partpayment_treaties
        total_brokerage_amt
        brokerage_chart
      }
    }
    # treaties
    treaties {
      treaty_id
      treaty_details
      treaty_reference
      order_hereon
      kek_reference
      currency
      treaty_payment_status

      receivable_payments {
        receivable_payment_id
        uuid
        payment_amount
        payment_detail
        created_at
        treaty_participant {
          treaty_participation_id
          treaty_participation_percentage
          layer_number
          reinsurer {
            reinsurer_id
            re_company_name
          }
        }
      }

      treaty_program {
        treaty_program_id
        treaty_name
        treaty_type
      }
      treaty_proportional_detail {
        treaty_proportional_detail_id
        profit_commission
        re_mgmt_expense
        ernpi
        overall_gross_premium
        losses_outstanding
        portfolio_entry
        created_at
      }
      insurer {
        insurer_company_name
        insurer_address {
          suburb
          region
          country
        }
      }
      employee {
        employee_last_name
        employee_first_name
        employee_phonenumber
        employee_email
      }

      treaty_np_detail {
        egrnpi
        burning_cost_rate
        loaded_burning_cost
        pure_burning_cost
      }
      treaty_np_payments {
        uuid
        installment_type
        treaty_payment_amount
        treaty_payment_details
        treaty_payment_comment
        created_at
        updated_at
        treaty_n_p_payment_id
        treaty_participant_payment {
          treaty_participationstreaty_participation_id
          treaty_p_paymentstreaty_p_payment_id
          treaty_np_paymentstreaty_np_payment_id
          brokerage_paid
          commission_paid
          nic_levy_paid
          withholding_tax_paid
          participant_payment_amount
        }
      }

      treaty_p_payments {
        treaty_p_payment_id
        treaty_payment_amount
        treaty_payment_details
        treaty_payment_comment
        surpulus_uuid
        treaty_accountstreaty_account_id
        created_at
        updated_at
        treaty_participant_payment {
          treaty_participationstreaty_participation_id
          treaty_p_paymentstreaty_p_payment_id
          treaty_np_paymentstreaty_np_payment_id
          brokerage_paid
          commission_paid
          nic_levy_paid
          withholding_tax_paid
          participant_payment_amount
        }
      }
      treaty_accounts {
        treaty_account_id
        claim_settled
        account_periods
        exchange_rate
        payment_status
        cash_loss
        account_year
        treaty_surpulus {
          treaty_account_surpulus_id
          surpulus_uuid
          gross_premium
        }
        treaty_account_deduction {
          treaty_account_deduction_id
          commission_amount
          nic_levy_amount
          gross_premium
          brokerage_amount
          withholding_tax_amount
          surpulus_uuid
          claim_settled
          cash_loss
          # Copied
        }

        treaty_participant_deduction {
          treaty_participation_share
          treaty_participationstreaty_participation_id
          treaty_participant_deduction_id
          withholding_tax_amount
          nic_levy_amount
          brokerage_amount
          commission_amount
          uuid
        }
      }
      layer_limit
      treaty_deduction {
        treaty_associate_deduction_id
        commission
        nic_levy
        withholding_tax
        brokerage
        treaty_period_to
        treaty_period_from
      }
      treaty_to_associates {
        layer_number
        sent_status
        treaty_to_associate_id
        reinsurer {
          reinsurer_id
          re_company_name
          re_company_email
        }
        reinsurer_representative {
          rep_last_name
          rep_first_name
          rep_primary_phonenumber
          rep_secondary_phonenumber
          rep_email
        }
      }
      treaty_claims {
        treaty_claim_id
        claim_date
        claim_paid
        claim_number
        layer_number
        insured_name
        treaty_comment
        date_of_loss
        policy_number
        expected_deductible
        receivable_payments {
          receivable_payment_id
          uuid
          payment_amount
          payment_detail
          created_at
          treaty_participant {
            treaty_participation_id
            treaty_participation_percentage
            layer_number
            reinsurer {
              reinsurer_id
              re_company_name
            }
          }
        }
        # created_at
      }
      treaty_participants {
        treaty_participation_id
        treaty_participation_percentage
        layer_number
        treaty_participant_payments {
          participant_payment_details
          treaty_participants_payment_id
        }
        treaty_participant_deductions {
          treaty_accountstreaty_account_id
          uuid
          brokerage
          commission
          nic_levy
          withholding_tax
          nic_levy_amount
          withholding_tax_amount
          commission_amount
          treaty_participation_share
          brokerage_amount
        }
        reinsurer {
          re_abbrv
          reinsurer_id
          re_company_name
          re_company_email
          reinsurer_address {
            suburb
            region
            country
          }
        }
      }
    }
    # offers
    offers {
      created_at
      offer_id
      offer_status
      sum_insured
      rate
      fac_sum_insured
      premium
      fac_premium
      brokerage
      facultative_offer
      payment_status
      commission
      commission_amount
      offer_participant {
        offer_participant_id
        participant_fac_premium
        offer_participant_percentage
        reinsurer {
          re_company_name
        }

        offer_participant_payment {
          offer_participant_payment_id
          offer_payment_amount
          paid_details
          offer_deduction_charge {
            offer_deduction_payment_id
            nic_levy_paid
            withholding_tax_paid
            brokerage_amount_paid
            commission_taken
          }
        }
      }
      offer_payment {
        offer_payment_id
        payment_details
        payment_amount
        offer_payment_comment
        created_at
        updated_at
      }
      classofbusiness {
        business_name
      }
      offer_detail {
        offer_details
        policy_number
        period_of_insurance_from
        period_of_insurance_to
        offer_comment
        insured_by
        currency
      }
      insurer {
        insurer_company_name
      }
    }
  }
`;
