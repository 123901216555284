import { useQuery } from "@apollo/client";
import { EMPLOYEES } from "../graphql/queries/employees";
import { Employees } from "../graphql/queries/__generated__/Employees";

export const useEmployees = (variables?: any) => {
  const { data, loading, error } = useQuery<Employees>(EMPLOYEES);
  return {
    employees: data?.employees ?? [],
    loading,
    error,
  };
};
