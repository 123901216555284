import { useQuery } from "@apollo/client";
import { ASSIGNED_CEDANTS } from "../graphql/queries/employees";
import {
  AssignedToMe,
  AssignedToMeVariables,
} from "../graphql/queries/__generated__/AssignedToMe";

export const useAssignedCedants = (variables?: AssignedToMeVariables) => {
  const { data, loading, error, networkStatus } = useQuery<
    AssignedToMe,
    AssignedToMeVariables
  >(ASSIGNED_CEDANTS, {
    variables,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  return {
    cedants: data?.assignedToMe ?? [],
    loading,
    error,
    networkStatus,
  };
};
