import gql from "graphql-tag";
import { INSURER_FIELDS } from "../fragments/insurer";
import { REINSURER_FIELDS } from "../fragments/reinsurers";

export const FETCH_CLASS_OF_BUSINESS = gql`
  query ClassOfBusinesses($category: String) {
  classOfBusinesses(category: $category) {
    class_of_business_id
    business_name
    business_category
    business_details
    created_at
  }
  offerOverview(filter_by: ["PENDING", "OPEN", "CLOSED"])
}

`;

export const CLASS_OF_BUSINESS = gql`
  query SingleClassOfBusiness($id: ID!) {
    singleClassOfBusiness(class_of_business_id: $id) {
      single_total_overview
      single_total_offers
      business_name
      business_category
      business_details
      class_of_business_id
    }
  }
`;

export const REINSURERS = gql`
  query Reinsurers {
    reinsurers {
      reinsurer_id
      re_abbrv
      re_company_name
      re_company_email
      re_company_website
      reinsurer_address {
        region
        suburb
        street
        country
      }
      reinsurer_representatives {
        reinsurer_representative_id
        rep_email
        rep_last_name
        rep_first_name
        rep_primary_phonenumber
        position
        rep_secondary_phonenumber
      }
    }
  }
`;

export const INSURERS = gql`
  ${INSURER_FIELDS}
  query Insurers {
    insurers {
      ...InsurerFields
    }
  }
`;

export const REINSURER = gql`
  ${REINSURER_FIELDS}
  query Reinsurer($id: ID!) {
    reinsurer(reinsurer_id: $id) {
      ...ReinsurerFields
    }
  }
`;

export const OFFERS = gql`
  query Offers($offer_status: [String]!, $year: String, $for: ID) {
    offers(offer_status: $offer_status, year: $year, for: $for) {
      offer_id
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      offer_detail {
        offer_detail_id
        offersoffer_id
        policy_number
        insured_by
        period_of_insurance_to
        period_of_insurance_from
        currency
        offer_comment
        offer_details
      }
      offer_claims {
        offer_claim_id
        claim_amount
        claim_comment
        claim_date
        created_at
        offer_claim_participants {
          offer_claimsoffer_claim_id
          offer_claim_participant_id
          reinsurer_id
          offer_participantsoffer_participant_id
          offer_participant_percentage
          re_company_name
          claim_share
          created_at
        }
      }
      offer_participant {
        offer_participant_id
        offer_participant_percentage
        offer_amount
        participant_fac_premium
        participant_fac_sum_insured
        offer_extra_charges {
          nic_levy
          agreed_brokerage_percentage
          withholding_tax
          agreed_commission
          agreed_commission_amount
          brokerage_amount
          nic_levy_amount
          withholding_tax_amount
        }
        offer_deduction_charge {
          nic_levy_paid
          withholding_tax_paid
          brokerage_amount_paid
          commission_taken
        }
        reinsurer {
          reinsurer_id
          re_company_name
          re_company_email
          reinsurer_address {
            country
          }
        }
      }
      insurer {
        insurer_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
        insurer_address {
          suburb
          region
          country
        }
      }
      classofbusiness {
        class_of_business_id
        business_name
        business_details
        created_at
      }
      created_at
    }
    offerOverview(filter_by: $offer_status, for: $for)
  }
`;

export const INPUT_OFFER_QUERIES = gql`
  ${INSURER_FIELDS}
  query InputOfferQueries {
    insurers {
      ...InsurerFields
    }
    classOfBusinesses {
      class_of_business_id
      business_name
      business_details
    }
  }
`;

export const CREATE_DISTRIBUTION_LIST_DATA = gql`
  query CreateDistributionListData {
    reinsurers {
      reinsurer_representatives {
        rep_last_name
        rep_first_name
        reinsurer_representative_id
        reinsurersreinsurer_id
        reinsurer {
          re_company_name
        }
      }
    }
  }
`;

export const SINGLE_OFFER = gql`
   query Offer($offer_id: ID!) {
    findSingleOffer(offer_id: $offer_id) {
      offer_id
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      offer_detail {
        offersoffer_id
        policy_number
        insured_by
        period_of_insurance_to
        period_of_insurance_from
        currency
        offer_comment
        offer_details
      }
      offer_participant {
        offer_participant_id
        offer_participant_percentage
        reinsurer {
          reinsurer_id
          re_company_name
          re_company_email
        }
      }

      classofbusiness {
        class_of_business_id
        business_name
        business_details
      }

      insurer {
        insurer_company_name
        insurer_company_email
        insurer_company_website
      }

      offer_associates {
        offer_to_associate_id
        sent_status
        reinsurer_representative {
          rep_email
          rep_first_name
          rep_last_name
          rep_primary_phonenumber
          rep_secondary_phonenumber
          reinsurer {
            re_company_name
            re_company_email
          }
        }
      }
      offer_claims {
        claim_amount
        claim_comment
        claim_date
        created_at
        offer_claim_id
        offer_claim_participants {
          claim_share
          created_at
          offer_claim_participant_id
          offer_claimsoffer_claim_id
          offer_participant_percentage
          offer_participantsoffer_participant_id
          re_company_name
          reinsurer_id
        }
      }
    }
  }

`;

export const FLEET = gql`
  query OfferFleet($policy_number: String, $skip: Int) {
    getOfferFleet(policy_number: $policy_number, skip: $skip) {
      offer_id
      vehile_reg_no
    }
  }
`;

export const DASHBOARD = gql`
  query Dashboard(
    $year: String
    $month: String
    $offersYear2: String
    $for: ID
  ) {
    offerOverview(filter_by: ["PENDING", "OPEN", "CLOSED"], for: $for)
    dashboardByPieChartData(year: $year, month: $month, for: $for) {
      total_cedants
      insurer_company_name
    }
    offers(offer_status: ["OPEN", "PENDING"], year: $offersYear2, for: $for) {
      offer_id
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      offer_detail {
        offersoffer_id
        policy_number
        insured_by
        period_of_insurance_from
        period_of_insurance_to
        currency
        offer_comment
        offer_details
      }
      offer_participant {
        reinsurer {
          re_company_name
        }
      }
      insurer {
        insurer_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
      }
      classofbusiness {
        class_of_business_id
        business_name
        business_details
        created_at
      }
      created_at
    }
    assignedOffers(offer_status: ["OPEN", "PENDING"]) {
      offer_id
      rate
      commission
      commission_amount
      brokerage
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      created_at
      updated_at
      insurer {
        insurer_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
        insurer_abbrv
      }
      offer_detail {
        offer_detail_id
        offersoffer_id
        policy_number
        insured_by
        period_of_insurance_from
        period_of_insurance_to
        currency
        offer_comment
        offer_details
        updated_at
      }
      classofbusiness {
        class_of_business_id
        business_name
        business_details
        single_total_overview
        single_total_offers
        created_at
      }
    }
  }
`;

export const INSURER = gql`
  ${INSURER_FIELDS}
  query Insurer($id: ID!) {
    insurer(insurer_id: $id) {
      ...InsurerFields
    }
  }
`;

export const GET_ISNURER_DEDUCTIONS = gql`
  query OfferparticipantDeductions($id: ID!, $offer_id: ID, $payment_id: ID!) {
    getOfferparticipantDeductions(
      insurer_id: $id
      offer_id: $offer_id
      payment_id: $payment_id
    )
  }
`;

export const CLAIM_OVERVIEW = gql`
  query ClaimOverview {
    claimOverview(offer_status: ["CLOSED"])
  }
`;

export const getStatementPaymennts = gql`
  query GetPaidChequesForStatement {
    getPaidChequesForStatement {
      cheque_number
      reinsured
      offer_ids
      payment_ids
    }
  }
`;


export const GET_BUSINESS_CATEGORIES = gql`
  query GetBusinessCategories {
    getBusinessCategories {
      name
    }
  }
`;

