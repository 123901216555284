import React from "react";
import { Suspense, useContext, Fragment } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import routes from "../routes";
import ConnectionDetector from "../components/ConnectionDetector";
import Dashboard from "../pages/Dashboard";
import Zoom from "react-reveal/Reveal";
import { AuthContext } from "../context/AuthContext";
import { version } from "../../package.json";
import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    0: "#456990",
    "1.0": "#3F83F6",
  },
  barThickness: 2,
  shadowBlur: 5,
});

const LoadingComponent = (props) => {
  return (
    <Fragment>
      <TopBarProgress />
    </Fragment>
  );
};

const AdminContent = () => {
  const { state } = useContext(AuthContext);

  const getRoutes = (routes) => {
    return routes.map((el, key) => {
      const Component = el.component;
      const MainComponent = () => (
        <Zoom effect="fadeInUp">
          <Component />
        </Zoom>
      );
      return el.layout === "/admin" &&
        el.roles.includes(state?.user?.position) ? (
        <Private
          key={key}
          path={el.layout + el.path}
          exact
          component={MainComponent}
        />
      ) : null;
    });
  };

  return (
    <div className="main-content" style={{ backgroundColor: "#262B3C" }}>
      {/* Main Content */}
      <Suspense fallback={LoadingComponent()}>
        <ConnectionDetector />
        <Switch>
          <Private exact path="/" component={Dashboard} />
          {getRoutes(routes)}
          <Route component={Dashboard} />
        </Switch>
      </Suspense>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              {new Date().getFullYear()} ©Visal re-insurance
              {/* {JSON.stringify(state?.user)} */}
            </div>
            <div className="col-sm-6">current verion: {version}</div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default AdminContent;

const Private = React.memo((props) => {
  const { state } = useContext(AuthContext);
  const { pathname } = useLocation();
  return !state.user ? (
    <Redirect to={{ pathname: "/auth", state: { pathname } }} />
  ) : (
    <Route {...props} />
  );
});
