import React, { Fragment, SetStateAction } from "react";
import { Selector } from "../../../components";
import { useAuth } from "../../../context/AuthContext";
import { useEmployees } from "../../../hooks/useEmployees";

type Props = {
  setSelectedemployee: React.Dispatch<SetStateAction<any>>;
  selectedemployee: any;
};

const ManagerEmployeePerformance = ({
  setSelectedemployee,
  selectedemployee,
}: Props) => {
  const { loading, employees } = useEmployees();
  const { user } = useAuth() as any;

  if (!["Managing Director", "System Administrator"].includes(user?.position))
    return null;

  return (
    <Fragment>
      <div className="card">
        <div className="card-body">
          <h4 className="card-title mb-4">
            Select employee to see performance
          </h4>
          <div className="row">
            <div className="col-sm-12">
              <fieldset className="w-auto p-2 border">
                <legend className="font-size-14">Select Employees</legend>
                <div className="col-md-12 flex-1 w-full">
                  <Selector
                    value={selectedemployee}
                    placeholder="Employees"
                    isLoading={loading}
                    // isMulti
                    onChange={(values: any) => setSelectedemployee(values)}
                    // components={{ Option: InsurerOption }}
                    options={employees?.map((el) => ({
                      value: el?.employee_id,
                      label:
                        el?.employee_first_name + " " + el?.employee_last_name,
                    }))}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ManagerEmployeePerformance;
