export default [
  {
    link: "/admin/",
    name: "Dashboard",
    icon: "bx bx-home-circle",
  },
  {
    link: "/admin/create-slip",
    name: "Create Slip",
    icon: "bx bxs-file-plus",
  },
  {
    link: "/admin/create-closing",
    name: "Create Closing",
    icon: "bx bx-lock-alt",
  },
  {
    link: "/admin/re-insurers",
    name: "Re-insurers",
    icon: "bx bx-group",
  },
  {
    link: "/admin/setup-business",
    name: "Setup Business",
    icon: "bx bx-group",
  },
  {
    link: "/admin/insurers",
    name: "Insurers",
    icon: "bx bx-group",
  },
  {
    link: "/admin/employees",
    name: "Employees",
    icon: "bx bx-home-circle",
  },
  {
    link: "/admin/reports",
    name: "Reports",
    icon: "bx bx-home-circle",
  },
];

const dashboard = [
  {
    link: "/admin/",
    name: "Dashboard",
    icon: "bx bx-home-circle",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
  },
];

const offers = [
  {
    link: "/admin/create-slip",
    name: "Create Slip",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bxs-file-plus",
  },
  {
    link: "/admin/create-closing",
    name: "Create Closing",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bx-lock-alt",
  },
  // {
  //   link: "/admin/endorsements",
  //   name: "Endorsements",
  //   roles: [
  //     "Managing Director",
  //     "Senior Broking Officer",
  //     "Broking Officer",
  //     "Frontline Executive",
  //     "Finance Officer",
  //     "System Administrator",
  //   ],
  //   icon: "bx bxs-file-plus",
  // },
];
const clients = [
  {
    link: "/admin/insurers",
    name: "Insurers",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bx-group",
  },
  {
    link: "/admin/re-insurers",
    name: "Re-insurers",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bx-group",
  },
  {
    link: "/admin/brokers",
    name: "Reinsurance Brokers",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bx-group",
  },
];
const others = [
  {
    link: "/admin/setup-business",
    name: "Setup Business",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "System Administrator",
    ],
    icon: "bx bx-group",
  },

  {
    link: "/admin/claims",
    name: "Claims",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bx-receipt",
  },
  {
    link: "/admin/employees",
    name: "Employees",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "System Administrator",
    ],
    icon: "bx bx-id-card",
  },
  {
    link: "/admin/reports",
    name: "Reports",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "Broking Officer",
      "Frontline Executive",
      "Finance Officer",
      "System Administrator",
    ],
    icon: "bx bx-box",
  },
];

export const treaty = [
  {
    link: "/admin/treaty-programs",
    name: "Treaty Programs",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "System Administrator",
      "Broking Officer",
    ],
    icon: "bx bx-collection",
  },
  {
    link: "/admin/treaty-claims",
    name: "Treaty Claims",
    roles: [
      "Managing Director",
      "Senior Broking Officer",
      "System Administrator",
      "Broking Officer",
    ],
    icon: "bx bx-receipt",
  },
];

export const deleteAccessRoles = [
  "Managing Director",
  "Senior Broking Officer",
  "System Administrator",
];
export const editAccessRoles = [
  "Managing Director",
  "Senior Broking Officer",
  "Broking Officer",
  "Frontline Executive",
  "Finance Officer",
  "System Administrator",
];

export const technicalTeam = [
  "CEO",
  "General Manager",
  "Senior Broking Officer",
  "System Administrator",
];

export const edit_broker_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];
export const delete_broker_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];
export const create_broker_access = [
  "System Administrator",
  "Senior Broking Officer",
  "General Manager",
];

export { clients, others, offers, dashboard };
