import gql from "graphql-tag";

export const REINSURER_FIELDS = gql`
  fragment ReinsurerFields on Reinsurer {
    re_company_name
    re_abbrv
    re_company_email
    re_company_website
    reinsurer_id
    reinsurer_address {
      street
      suburb
      region
      country
    }
    treaty_participations {
      treaty_participation_id
      treaty_participation_percentage
      treaty {
        treaty_id
        treaty_reference
        currency
        layer_limit
        treaty_details
        treaty_payment_status
        treaty_program {
          treaty_name
          treaty_type
          treaty_program_id
        }
        employee {
          employee_last_name
          employee_first_name
        }
        treaty_deduction {
          treaty_associate_deduction_id
          commission
          withholding_tax
          nic_levy
          treaty_period_to
          treaty_period_from
          brokerage
        }
        treaty_np_payments {
          created_at
          updated_at
          uuid
          treaty_payment_details
          treaty_payment_comment
          treaty_payment_amount
          treaty_n_p_payment_id
          # Participants Payments
          treaty_participant_payment {
            treaty_participants_payment_id
            treaty_participationstreaty_participation_id
            treaty_np_paymentstreaty_np_payment_id
            # treaty_participation {
            #   treaty_participation_id
            # }
            nic_levy_paid
            withholding_tax_paid
            participant_payment_amount
            brokerage_paid
            commission_paid
          }
        }

        treaty_p_payments {
          created_at
          updated_at
          treaty_p_payment_id
          treaty_payment_details
          treaty_payment_comment
          treaty_accountstreaty_account_id
          treaty_payment_amount
          treaty_participant_payment {
            treaty_participants_payment_id
            treaty_participationstreaty_participation_id
            treaty_p_paymentstreaty_p_payment_id
            nic_levy_paid
            withholding_tax_paid
            participant_payment_amount
            brokerage_paid
            commission_paid
          }
        }
        treaty_accounts {
          treaty_account_id
          claim_settled
          account_periods
          gross_premium
          treaty_account_deduction {
            commission_amount
            nic_levy_amount
            brokerage_amount
            withholding_tax_amount
          }
        }
      }
      treaty_participant_payments {
        treaty_participants_payment_id
        treaty_participationstreaty_participation_id
        treaty_p_paymentstreaty_p_payment_id
        treaty_np_paymentstreaty_np_payment_id
        participant_payment_status
        participant_payment_details
        nic_levy_paid
        withholding_tax_paid
        participant_payment_amount
        surpulus_uuid
        brokerage_paid
        commission_paid
        created_at
        updated_at
      }
    }
    reinsurer_overview {
      brokerage_chart
      total_paid
      total_unpaid
      total_closed
      total_pending
      total_fac_premium
      total_brokerage_amt
      total_nic_tax
      total_withholding_tax
      treaties_overview {
        total_treaties
        total_proportional
        total_nonproportional
        total_unpaid_treaties
        total_paid_treaties
        total_partpayment_treaties
        total_brokerage_amt
        brokerage_chart
      }
    }

    reinsurer_representatives {
      reinsurer_representative_id
      rep_first_name
      rep_last_name
      rep_primary_phonenumber
      rep_secondary_phonenumber
      rep_email
      position
    }

    offers_participant {
      offersoffer_id
      offer_participant_id
      offer_detail {
        currency
        policy_number
      }
      offer_participant_percentage
      participant_fac_premium
      offer_extra_charges {
        offer_extra_charge_id
        withholding_tax
        agreed_commission
        nic_levy
        agreed_brokerage_percentage
      }
      offer_participant_payment {
        offer_participant_payment_id
        offer_payment_amount
        paid_details
        offer_participant_payment_comment
        payment_status
        created_at
        updated_at
        offer_deduction_charge {
          offer_deduction_payment_id
          nic_levy_paid
          withholding_tax_paid
          brokerage_amount_paid
          commission_taken
        }
      }
      reinsurer_offers_only {
        offer_id
        rate
        premium
        brokerage
        fac_premium
        facultative_offer
        commission
        fac_sum_insured
        sum_insured
        created_at
        offer_status
        payment_status
        classofbusiness {
          business_name
          class_of_business_id
          business_details
        }
        insurer {
          insurer_company_name
        }
        offer_detail {
          insured_by
          currency
          policy_number
          period_of_insurance_from
          period_of_insurance_to
          offer_comment
          offer_details
        }
      }
    }
  }
`;
